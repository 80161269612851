// Checkout
.checkout-progress-bar {
  font-size: 0;
  line-height: 1.4;
  counter-reset: i;

  > div:first-of-type {
    .step {
      &:before {
        border-radius: 0.6rem 0 0 0.6rem;
      }
    }
  }

  > div:last-of-type {
    .step {
      &:before {
        border-radius: 0 0.6rem 0.6rem 0;
      }
    }
  }

  .step {
    display: block;
    position: relative;
    margin: 0;
    text-align: center;
    vertical-align: top;

    &:before {
      position: absolute;
      top: 1.9rem;
      margin-top: 0;
      left: 0;
      width: 100%;
      height: 0.7rem;
      transition: background 0.3s;
      background-color: #e4e4e4;
      content: "";
    }

    > span,
    > a {
      display: inline-block;
      width: 90%;
      padding-top: 4.2rem;
      font-size: 1.4rem;
      word-wrap: break-word;
      margin-top: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      font-weight: 500;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 50%;
        transition: background 0.3s;
        border-radius: 50%;
        content: "";
        margin-top: 0.8rem;
      }

      &:before {
        width: 2.9rem;
        height: 2.9rem;
        margin-left: -1.4rem;
        background-color: #e4e4e4;
      }

      &:after {
        top: 0.5rem;
        width: 1.9rem;
        height: 1.9rem;
        margin-left: -0.9rem;
        background: #fff;
        color: #777;
        font-weight: 600;
        content: attr(data-step);
        counter-increment: i;
        font-size: 1.4rem;
        line-height: 2.1rem;
      }
    }

    &.active {
      &:before,
      > span:before,
      > a:before {
        background-color: $secondary-color;
      }

      > span:after,
      > a:after {
        content: "";
      }

      .fa-check {
        right: -0.2rem;
        left: 0;
        top: 1.6rem;
        bottom: 0;
        color: #777;
        height: 1.4rem;
        width: 1.4rem;
        position: absolute;
        margin: auto;
        margin-top: 0;
        z-index: 1;
      }
    }
  }
}

.checkout-steps {
  li {
    margin-bottom: 1.5rem;

    form {
      margin-bottom: 0;
      padding-bottom: 2rem;
      border-bottom: 1px solid #ccc;

      &:last-of-type {
        padding-bottom: 0.1rem;
        border-bottom: 0;
      }

      p {
        margin-bottom: 1rem;
        color: #777;
        font-size: 1.3rem;
      }
    }

    .form-footer {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.step-title {
  padding-bottom: 9px;
  font-weight: 400;
  font-size: 2.1rem;
  margin-bottom: 0;
  @include clearfix;

  .step-title-edit {
    margin-top: 0.4rem;
    float: right;
    font-size: 1.4rem;
  }
}

.shipping-step-addresses {
  @include clearfix;
  margin-bottom: 2rem;
  font-size: 0;
}

.shipping-address-box {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 240px;
  margin: 0;
  padding: 1.8rem 3.2rem 1rem 1.8rem;
  transition: 0.3s border-color;
  border: 0.2rem solid transparent;
  font-size: 1.3rem;
  line-height: 3rem;
  vertical-align: top;
  word-wrap: break-word;

  &.active {
    border-color: $secondary-color;

    &:after {
      content: "\e84e";
      font-family: "porto";
      position: absolute;
      right: 0;
      top: 0;
      height: 2.7rem;
      width: 2.7rem;
      font-size: 1.9rem;
      line-height: 2.1rem;
      padding-top: 0.2rem;
      text-align: center;
      background-color: $secondary-color;
      color: #ffffff;
    }

    .address-box-action {
      visibility: hidden;
    }
  }
}

.address-box-action {
  margin-top: 2rem;

  .btn {
    font-family: $font-family;
    letter-spacing: 0;
    text-transform: initial;

    &.btn-sm {
      padding: 0.85rem 1.2rem;
      font-size: 1.3rem;

      &.btn-link {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.btn-new-address {
  margin-bottom: 1.2rem;
  padding: 0.95rem 1.3rem;
  font-family: $font-family;
  letter-spacing: 0;
  text-transform: initial;
}

.table-step-shipping {
  max-width: 600px;

  tr {
    td {
      border: 0;
      border-bottom: 1px solid #cccccc;
      padding: 1.8rem 1.5rem;
    }
  }
}

.checkout-steps-action {
  @include clearfix;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 3em;

  .btn {
    min-width: 70px;
    text-align: center;
  }
}

.order-summary {
  background: #f5f5f5;
  border: 1px solid #ddd;

  .order-summary-wrapper {
    h3 {
      font-weight: 400;
      font-size: 2rem;
      margin-bottom: 1.3rem;
    }

    h4 {
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 0;
      padding: 0.8rem 0 0.8rem 0;
    }
  }
}

.table-mini-cart {
  margin-bottom: 0;

  tr {
    td {
      padding: 2rem 0;
      border: 0;
      vertical-align: top;
    }

    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .product-col {
    -ms-flex-align: start;
    align-items: flex-start;

    .product-image-container {
      flex: 0 0 75px;
      max-width: 75px;
      margin-right: 1.5rem;
      vertical-align: top;

      img {
        min-height: 60px;
        object-fit: cover;
        mix-blend-mode: multiply;
      }
    }

    > div {
      vertical-align: top;
      width: 100%;
    }
  }

  .price-col {
    padding-top: 2.5rem;
    font-size: 1.4rem;
    font-weight: 400;
  }

  .product-title {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .product-qty {
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    align-items: center;
    color: #777;
    font-size: 1.2rem;
    line-height: 2rem;

    .input-group {
      margin-left: 0.5rem;

      button {
        font-weight: bold;
      }

      .form-control {
        font-size: 1.1rem;
        line-height: 1.1rem;
        border: 1px solid #ccc !important;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:active,
        &:focus {
        }
      }
    }
  }
}

.checkout-payment {
  margin-bottom: 3rem;

  .step-title {
    margin-bottom: 1.8rem;
  }

  h4 {
    font-weight: 400;
  }
}

#checkout-shipping-address {
  display: none;
  padding-left: 3rem;
  line-height: 3rem;

  &.show {
    display: block;
  }
}

#new-checkout-address {
  display: none;

  &.show {
    display: block;
  }
}

.checkout-discount {
  margin-top: 2rem;
  margin-bottom: 3rem;

  form {
    max-width: 480px;
    margin-bottom: 0;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }

  h4 {
    margin-bottom: 0;
    border-top: 1px solid #ccc;
    font-size: 1.4rem;
    font-weight: 400;

    a {
      position: relative;
      display: block;
      padding: 1rem 2rem 1rem 0;

      &:after {
        font-family: "porto";
        content: "\e81b";
        position: absolute;
        display: inline-block;
        right: 0.4rem;
        top: 50%;
        font-size: 1.5rem;
        transform: translateY(-50%);
      }

      &.collapsed:after {
        content: "\e81c";
      }
    }
  }
}

.checkout-info-box {
  margin-bottom: 3rem;
  font-size: 1.3rem;
  line-height: 2.7rem;

  .step-title {
    margin-bottom: 1.8rem;
  }

  address {
  }
}

@include mq(sm) {
  .checkout-progress-bar {
    .step {
      width: 100%;
    }
  }

  .shipping-address-box {
    width: 50%;
  }
}

@include mq(md) {
  .shipping-address-box {
    width: 33.33%;
  }
}
